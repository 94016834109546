(function(APP, $, window) {
  'use strict';

  /**
   * Front page class
   * @param {Object} options
   */
  function HeroSlider(options) {
    if (!(this instanceof HeroSlider)) return new HeroSlider(options);

    this.$heroSlider = $('.js-hero-slider');
    this.$heroSliderDots = $('.js-hero-slider-dot');
    this.$heroSliderBackgrounds = $('.js-hero-slider-background');

    this.$heroSlider.flickity({
      cellAlign: 'left',
      contain: true,
      wrapAround: true,
      prevNextButtons: false,
      pageDots: false,
      lazyLoad: true,
      on: {
        ready: function() {
          this.$heroSlider.addClass('is-ready');
        }.bind(this),
        change: function(index) {
          this.$heroSliderDots.removeClass('is-active');
          this.$heroSliderDots
            .filter('[data-index="' + index + '"]')
            .addClass('is-active');
          this.$heroSliderBackgrounds.removeClass('is-active');
          this.$heroSliderBackgrounds
            .filter('[data-index="' + index + '"]')
            .addClass('is-active');
        }.bind(this),
      },
    });

    this.$heroSliderDots.on(
      'click',
      function(event) {
        this.$heroSlider.flickity(
          'select',
          event.currentTarget.getAttribute('data-index')
        );
      }.bind(this)
    );

    return this;
  }

  $(window).on('load', function() {
    APP.store.HeroSlider = new HeroSlider();
  });
})(APP, jQuery, window);
